<template>
  <div v-if="tabs.length > 1" class="infopanel">
    <v-tabs-header :items="tabs" v-model="activeTab"/>
    <v-tabs :items="tabs" v-model="activeTab">
      <template v-for="tab in tabs" v-slot:[tab.key]>
        <generic-info-panel
          :key="tab.key"
          v-bind="$props"
          :show-relations="false"
          :properties="tabsFields[tab.label]"
        />
      </template>
    </v-tabs>
    <generic-info-panel
      v-bind="$props"
      :properties="[]"
      @relation="(r, d) => $emit('relation', r, d)"
    />
  </div>
  <generic-info-panel
    v-else
    v-bind="$props"
    @relation="(r, d) => $emit('relation', r, d)"
  />
</template>

<script>
import mapValues from 'lodash/mapValues'
import VTabs from '@/ui/Tabs.vue'
import VTabsHeader from '@/ui/TextTabsHeader.vue'
import GenericInfoPanel from '@/components/GenericInfopanel.vue'

const Tabs = {
  Hrob: [
    'id_hrob', 'hrob_cislo', 'id_hrbitov_cast', 'id_hrob_typ', 'id_obsazenost', 'napis',
    'delka', 'sirka', 'vymera', 'vymera_korigovana', 'zamereno', 'pohrbeni', 'poznamka',
    'karta_url', 'foto', 'foto_url', 'foto2', 'foto2_url'
  ],
  Smlouva: [
    'smlouva_cislo', 'smlouva_platnost_od', 'smlouva_platnost_do', 'smlouva_platnost_doba',
    'smlouva_platba_datum', 'smlouva_platba_typ'
  ],
  Nájemce: ['najemce_jmeno', 'najemce_obec', 'najemce_ulice_cp', 'najemce_psc', 'zmocnenec_jmeno', 'zmocnenec_obec', 'zmocnenec_ulice_cp', 'zmocnenec_psc']
}

export default {
  name: 'Grave_Panel',
  components: { VTabs, VTabsHeader, GenericInfoPanel },
  props: {
    project: Object,
    layer: Object,
    feature: Object
  },
  data () {
    return {
      activeTab: 0
    }
  },
  computed: {
    tabsFields () {
      const orderedFields = this.layer.info_panel_fields || this.layer.attributes.map(a => a.name)
      return mapValues(Tabs, fields => orderedFields.filter(f => fields.includes(f)))
    },
    tabs () {
      return Object.keys(Tabs).filter(name => this.tabsFields[name].length > 0).map((name, i) => ({ key: i, label: name }))
    }
  }
}
</script>

<style lang="scss" scoped>
.infopanel {
  .generic-infopanel {
    :deep(.fields:empty) {
      border: none;
    }
  }
  @media (min-width: 501px) {
    width: 400px;
  }
}
</style>
